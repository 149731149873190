<template>
    <div id = "four-oh-four">
        <h1> 404 </h1>
        <h2> Seems like the page you are trying to access does not exist! </h2>
    </div>      
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
#four-oh-four {
    height: 85vh;

    h2 {
        margin-top: 25vh;
    }
}

</style>